<template>
    <div style="height: 100%">
        <common-nav-bar :title="article.title"></common-nav-bar>
        <div class="article">
            <div class="articleTop">
                <h3 class="articleTitle">{{ article.title }}</h3>
                <div class="articleTime"><span>益耳官方</span><span>2022-03-18</span></div>
            </div>
            <div class="articleBody" v-html="article.content"></div>
        </div>
    </div>
</template>

<script>
import { getArticle } from '@/services/article';
import { toast } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    name: 'detail',
    components: { CommonNavBar },
    data() {
        return {
            article: {},
        };
    },
    created() {
        const { id } = this.$route.query;
        this.getArticleDetail(id);
    },
    methods: {
        async getArticleDetail(id) {
            const { code, message, data } = await getArticle(id);
            if (!code) {
                return toast(message);
            }
            this.article = data;
        },
    },
};
</script>

<style scoped>
.article {
    background: #f9f9f9;
    height: 100%;
    box-sizing: border-box;
    padding: 5px 10px 10px;
}
.articleTop {
    border-radius: 8px;
    overflow: hidden;
}
.articleTitle {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #4ab8ab;
    margin: 0;
    padding: 10px;
}
.articleTime {
    font-size: 12px;
    color: #999;
    padding: 10px;
    /* border-bottom: 1px dashed #eee; */
    background: #f1f1f1;
}
.articleTime span {
    margin-right: 10px;
}
.articleBody {
    font-size: 14px;
    color: #666;
    line-height: 24px;
    text-indent: 15px;
    margin-top: 10px;
    padding: 0 15px;
}

.articleBody /deep/ img {
    width: 100% !important;
}
</style>
